<template>
    <div>
        <div class="contact-form">
            <!-- action="http://c1860865.ferozo.com/service/contacto.php" -->
            
            <form method="POST" class="form-group" v-on:submit.prevent="submitForm">
                <div class="row form-inner">

                    <div v-if="sendResponse == 'success'" class="col-12 justify-content-center text-center success-message">
                        <div class="icon"><img src="./../assets/images/check.svg" alt=""></div>
                        <h2>Su consulta fue enviada.</h2>
                        <h4>Le responderemos a la brevedad</h4>
                    </div>
                    <div v-else-if="sendResponse == 'error'" class="col-12 justify-content-center text-center success-message">
                        <div class="icon"><img src="./../assets/images/check.svg" alt=""></div>
                        <h2>Su consulta fue enviada.</h2>
                        <h4>Le responderemos a la brevedad</h4>
                    </div>
                    <template v-else>
                        <div class="col-12">
                            <h5>¡Haga su consulta sin cargo!</h5>
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control" type="text" name="name" v-model="formInputs.name" placeholder="Nombre" required>
                            <input class="form-control" type="email" name="mail" v-model="formInputs.mail" placeholder="Mail" required>
                            <input class="form-control" type="text"  name="phone" v-model="formInputs.phone" placeholder="Teléfono" required>
                        </div>
                        <div class="col-sm-6">
                            <input class="form-control" type="text" name="address" v-model="formInputs.address" placeholder="Dirección (Local)" required>
                            <input class="form-control" type="text" name="bussiness" v-model="formInputs.bussiness" placeholder="Rubro" required>
                            <input class="form-control" type="text" name="surface" v-model="formInputs.surface" placeholder="Superficie (m2)" required>
                        </div>
                        <div class="col-sm-6 texts">
                            <h5>Asesoramiento Personalizado</h5>
                            <h5>Atención Permanente</h5>
                        </div>
                        <div class="col-sm-6 texts">
                            <h5>Proceso Veloz</h5>
                            <h5>Soluciones Integrales</h5>
                        </div>
                    </template>
                    
                </div>
                <div class="row">
                    <div v-if="sendResponse == 'none'" class="col-md-12 submit-action justify-content-center text-center">
                        <transition name="fade">
                            <button v-if="loading" class="btn btn-custom-1 loading-btn">
                                <img src="./../assets/images/loader.svg" alt="">
                            </button>
                            <button v-else class="btn btn-custom-1">
                                <span >ENVIAR CONSULTA</span>
                            </button>
                        </transition>
                    </div>

                    <div v-else-if="sendResponse == 'error'">
                        <br>
                        <button class="btn btn-custom-1">
                            <span >Reintentar</span>
                        </button>
                    </div>                    

                </div>
            </form>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        data() {
            return {
                formInputs: { name: '', mail: '', phone: '', address: '', bussiness: '', surface: '' },
                sendResponse: 'none',
                loading: 0
            }
        },
        mounted() {
        },
        methods: {
            submitForm() {
                let _this = this;

                let data = this.formInputs;
                let formData = new FormData();
                
                for(var key in data){
                    formData.append(key, this.formInputs[key]);
                }

                this.loading = 1;

                axios({
                    method: 'POST',
                    url: 'https://habilitarcaba.com/service/contacto.php',
                    data: formData,
                    // headers: {'Content-Type': 'multipart/form-data' }
                    })
                    .then(function (response) {
                        
                        if(response.data) {
                            if(response.data == 1) {
                                _this.sendResponse = 'success';
                            } else {
                                _this.sendResponse = 'error';
                            }
                        } else {
                            console.log(response);
                        }
                    })
                    .catch(function (response) {
                        //handle error
                        console.log(response);
                    }).finally(() => {
	
                        _this.loading = 0;
                    });
                
            }
        }
    }
    
</script> 

<style>
    .fade-enter-active, .fade-leave-active {
        transition: opacity .5s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }
</style>