<template>
	<div>
		<b-navbar toggleable="lg">
			<!-- <b-navbar-brand href="#">
				<img src="./../assets/images/main-logo.svg" alt="Habilitaciones CABA">
			</b-navbar-brand> -->
			<div class="col-6 col-sm-6 col-md-4 main-logo-column">
				<img class="main-logo" src="./../assets/images/main-logo.svg" alt="Habilitaciones CABA">
			</div>

			<div class="col-6 col-sm-6 col-md-4 secondary-logo-column mobile">
				<img class="logo" src="./../assets/images/opt/main-logo-2.webp" alt="Habilitaciones CABA">
			</div>

			<div class="col-6 col-sm-6 col-md-4 cta-column">
				<b-nav-text href="#">Llámenos al</b-nav-text>
				<a :href="whatsAppWebHref" class="btn btn-custom-2" target="_blank">
					<img class="icon" src="./../assets/images/whatsapp-logo-small.svg" alt="Whatsapp CTA">
					11-5016-6649
				</a>

			</div>
			<div class="col-12 col-sm-6 col-md-4 secondary-logo-column desktop">
				<img class="logo" src="./../assets/images/opt/main-logo-2.webp" alt="Habilitaciones CABA">
			</div>
			<b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

			<!-- <b-collapse id="nav-collapse" is-nav> -->
				<!-- <b-navbar-nav>
					<b-nav-item href="#">Link</b-nav-item>
					<b-nav-item href="#" disabled>Disabled</b-nav-item>
				</b-navbar-nav> -->

				<!-- Right aligned nav items -->
				
				<!-- <b-navbar-nav class="ml-auto"> -->
					
				
					<!-- <b-nav-item-dropdown right>
						<template v-slot:button-content>
							<em>User</em>
						</template>
						<b-dropdown-item href="#">Profile</b-dropdown-item>
						<b-dropdown-item href="#">Sign Out</b-dropdown-item>
					</b-nav-item-dropdown> -->
				<!-- </b-navbar-nav> -->
			<!-- </b-collapse> -->
		</b-navbar>
	</div>

</template> 

<script>

	import Common from './../mixins/common.js'

	export default {
		mixins: [ Common ],
	}

</script>