<template>
	<div id="app">
		<nav-component></nav-component>
		<main-component></main-component>
	</div>
</template>

<script>
	import Main from './sections/Main.vue'
	import Nav from './components/Nav.vue'

	export default {
		name: 'App',
		components: {
			'main-component': Main,
			'nav-component': Nav
		}
	}
</script>
