import Vue from 'vue'
import App from './App.vue'

const axios = require('axios');

// Bootstrap Vue (https://bootstrap-vue.org/)
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)

// Vue Meta (https://vue-meta.nuxtjs.org/)
import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// Carousel (https://ssense.github.io/vue-carousel/guide/)
import VueCarousel from 'vue-carousel'
Vue.use(VueCarousel)

import './assets/sass/styles.sass'

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
