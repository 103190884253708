<template>
	<div>
		<section class="container-fluid first-section">
			<div class="container">
				<div class="overlay-vignette"></div>
				<div class="row">

					<div class="col-md-6 col1">
						<h5>Habilitaciones comerciales, <br> servicios, oficinas e industrias</h5>
						<h1>Habilite su local <br> comercial en CABA</h1>
						<div>
							<h4>¡Pagá en cuotas!</h4>
							<div class="horizontal-list payment-methods">
								<ul>
									<li><img src="./../assets/images/visa-logo.svg" alt=""></li>
									<li><img src="./../assets/images/mastercard-logo.svg" alt=""></li>
									<li><img src="./../assets/images/mercadopago-logo.svg" alt=""></li>
								</ul>
							</div>
						</div>
					</div>

					<div class="col-md-6 col2">
						<!-- Contact Form -->
						<contact-form-component></contact-form-component>
					</div>
				</div>
			</div>

		</section>
		<section>
			<div class="container-fluid">
				<div class="container">
					<div class="row">
						<div class="col-md-12 mb-4 text-center">
							<h2 class="palette2-color bold ">PROCESO PARA HABILITAR TU LOCAL</h2>
						</div>
						
						<div class="col-md-6 col-lg-3 square-small-container">
							<div class="inner">
								<div class="inner2">
									<div class="title-with-number">
										<div class="number-container"><div class="number">1</div></div>
										<h3>Inspección Visual</h3>
									</div>
									<p>Relevamiento del local y <br> asesoramiento completo</p>
								</div>
							</div>
						</div>

						<div class="col-md-6 col-lg-3 square-small-container">
							<div class="inner">
								<div class="inner2">
									<div class="title-with-number">
										<div class="number-container"><div class="number">2</div></div>
										<h3>Inicio del trámite</h3>
									</div>
									<p>Confección y presentación de la documentación técnica y plano  <br> (de corresponder)</p>
								</div>
							</div>
						</div>

						<div class="col-md-6 col-lg-3 square-small-container">
							<div class="inner">
								<div class="inner2">
									<div class="title-with-number">
										<div class="number-container"><div class="number">3</div></div>
										<h3>Tasa municipal</h3>
									</div>
									<p>Pago de tarifarias emitidas por GCBA de acuerdo al rubro y categoría del trámite</p>
								</div>
							</div>
						</div>

						<div class="col-md-6 col-lg-3 square-small-container">
							<div class="inner">
								<div class="inner2">
									<div class="title-with-number">
										<div class="number-container"><div class="number">4</div></div>
										<h3>Emisión de oblea</h3>
									</div>
									<p>Se obtiene la oblea de código QR que autoriza el inicio de la actividad</p>
								</div>
							</div>
						</div>
						
					</div>
				</div>
			</div>
		</section>

		<section class="management">
			<div class="container-fluid">
				<div class="row inner">
					<h2>Gestión integral en habilitaciones comerciales, <br> servicios, oficinas e industrias.</h2>
				</div>
			</div>
		</section>

		<section class="mb-0 pb-0">
			<div class="container">
				<div  class="row about-us justify-content-center">
					<h2 class="mb-4">SOBRE NOSOTROS</h2>
					<p class="mb-5">
						Gestión integral en habilitaciones comerciales, servicios, oficinas e industrias.
						 Somos un equipo de profesionales matriculados con más de 10 años de experiencia y cientos de locales
						  habilitados en toda la Ciudad Autónoma de Buenos Aires, con referencias 
						 comprobables por calle, barrio y comuna. Nuestra prioridad es brindar un servicio completo 
						 y personalizado para acompañarte durante todo el proceso del trámite, llevando adelante cada
						  uno de los pasos necesarios hasta la obtención de la habilitación definitiva de tu local y
						   manteniendo incluso el contacto ante cualquier duda que surja posteriormente. Contamos también 
						   con profesionales para asesoramiento legal y contable respecto a la actividad comercial.  <br>
						   CONSULTA ANTES DE ALQUILAR

					</p>
					<div class="col-12 col-sm-6 col-lg-4 mb-2 item-with-icon">
						<img class="icon" src="./../assets/images/opt/icon1.webp" alt="">
						<h4 class="title">Consultanos sin cargo</h4>
						<p class="text">Hacé tu consulta sin compromiso mediante el formulario, WhatsApp o teléfono al 11-5016-6649, vía mail a digitalizacionescad@hotmail.com o en nuestras redes</p>
					</div>
					<div class="col-12 col-sm-6 col-lg-4 mb-2 item-with-icon">
						<img class="icon" src="./../assets/images/opt/icon2.webp" alt="">
						<h4 class="title">Formas de pago</h4>
						<p class="text">Se podrá abonar en efectivo, transferencia bancaria, crédito o débito pudiendo financiar los honorarios hasta en 12 cuotas mediante MercadoPago</p>
					</div>
					<div class="col-12 col-sm-12 col-lg-4 mb-2 item-with-icon">
						<img class="icon" src="./../assets/images/opt/icon3.webp" alt="">
						<h4 class="title">Consulte por trámites adicionales</h4>
						<p class="text">Ley 5920 de Autoprotección, emplazamiento de mesas y sillas en espacio público, ventilación mecánica, Impacto ambiental, confección de planos, digitalización de planos, documentación de obra, etc.</p>
					</div>
				</div>
			</div>

		</section>

		<section class="carousel-container">
			<div class="container customers">
				<div class="row justify-content-center">
					<h2 class="bold palette2-color">NUESTROS CLIENTES</h2>
				</div>
				
				<carousel 
				:perPage="4" 
				:autoplay="true" 
				:autoplayTimeout="5000" 
				:loop="true" 
				:navigationEnabled="true" 
				:paginationEnabled="false"
				class="carousel">
					<slide><img src="./../assets/images/opt/clientes-1.webp" alt="Cliente"></slide>
					<slide><img src="./../assets/images/opt/clientes-2.webp" alt="Cliente"></slide>
					<slide><img src="./../assets/images/opt/clientes-3.webp" alt="Cliente"></slide>
					<slide><img src="./../assets/images/opt/clientes-4.webp" alt="Cliente"></slide>
					<slide><img src="./../assets/images/opt/clientes-5.webp" alt="Cliente"></slide>
					<slide><img src="./../assets/images/opt/clientes-6.webp" alt="Cliente"></slide>
					<slide><img src="./../assets/images/opt/clientes-7.webp" alt="Cliente"></slide>
					<slide><img src="./../assets/images/opt/clientes-8.webp" alt="Cliente"></slide>
				</carousel>
			</div>
		</section>

		<section class="pre-footer">
			<div class="container">
				<div class="overlay-vignette"></div>
				<div class="row relative">

					<div class="col-md-6 col1">
						<h5 class="title">Contactate con nosotros</h5>
						<p>
							Para mayor información, no dude en contactarse con nosotros <br>
							mediante el siguiente formulario, o a través de nuestras vías <br> de comunicación.
						</p>
						<div class="icon-and-text"><img class="icon" src="./../assets/images/whatsapp-logo.svg" alt="">(+54 11) 15 5016 6649 </div>
						<div class="icon-and-text"><img class="icon invert" src="./../assets/images/mail-icon.svg" alt="">digitalizacionescad@hotmail.com </div>
						<div class="horizontal-list social-icons">
							<ul>
								<li><a href="https://www.facebook.com/habilitarcaba" target="_blank">
									<img class="icon" src="./../assets/images/facebook-logo.svg" alt=""/> 
								</a></li>
								<li><a href="https://www.instagram.com/habilitarcaba/" target="_blank">
									<img class="icon" src="./../assets/images/instagram-logo.svg" alt=""/> 
								</a></li>
							</ul>
						</div>
					</div>

					<div class="col-md-6 col2">
						<!-- Contact Form -->
						<contact-form-component></contact-form-component>
					</div>
					<div class="isologo-cpic">
						<img src="./../assets/images/isologo-cpic.svg" alt="">
					</div>

				</div>
			</div>
		</section>
		
		<a :href="whatsAppWebHref" class="floating-whatsapp-cta" target="_blank">
			<div class="inner">
				<div class="inner-circle">
					<img src="./../assets/images/whatsapp-logo-2.svg" alt="hatsApp Logo">
				</div>
			</div>
		</a>

		<!-- <footer>
			Desarrollado por 
			<a href="https://vimanastudio.com.ar" class="glow" target="_blank">
				<img class="vimana-logo" src="./../assets/images/opt/vimanastudio-logo.webp" alt="Vimana Studio Logo"> 
				<span>Vimana Studio</span>
			</a>
		</footer> -->
	</div>
</template>

<script>
	import Nav from './../components/Nav.vue'
	import ContactForm from './../components/ContactForm.vue'
	import CommonMixin from './../mixins/common'

	export default {
		mixins: [ CommonMixin ],

		components: {
			'nav-component': Nav,
			'contact-form-component': ContactForm
		},
		
		data() {
			return {
				
			}
		},

		metaInfo: {
			title: 'Habilitar Caba',
			titleTemplate: 'Habilitaciones Caba',
			meta: [
                { name:     'description',  content: 'Habilitaciones comerciales, servicios, oficinas e industrias' },
				{ name:     'robots',       content: 'index, follow' },
                { property: 'og:title',     content: 'Descripción del sitio' },
                { property: 'og:site_name', content: 'Habilitaciones Caba' },
                { property: 'og:type',      content: 'website' },
				{ property: 'keywords',     content: 'Habilitaciones CABA, Habilitaciones comerciales, servicios, oficinas e industrias, habilitación de locales comerciales en CABA, proceso para habilitar tu local, inspección visual, asesoramiento completo, inicio del trámite, confección y presentación de documentación técnica, pago de tarifarias, emisión de oblea, gestión integral en habilitaciones comerciales, servicios, oficinas, consultorios e industrias, equipo de profesionales matriculados, más de 15 años de experiencia, referencia comprobable, asesoramiento sobre normativas vigentes, consulta antes de alquilar, formas de pago, abono en efectivo, transferencia bancaria, crédito o débito, financiamiento en 12 cuotas, servicios ofrecidos, ley 5920 de autoprotección, transferencia de habilitaciones, consulta de usos, APH y edificios protegidos, emplazamiento de mesas y sillas en espacio público, publicidad en vía pública, ventilación mecánica, impacto ambiental, rastreo de planos, atención personalizada, atención permanente, soluciones integrales' },
            ]
			// script: [
			// 	{ src: '/data/analytics.js', async: true, defer: true }
			// ],
		}
	}
</script>
