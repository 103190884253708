export default {
	data() {
		return {
			whatsAppWebHref: 'https://wa.me/5491150166649',
		}
	},
	mounted() {
			
	},
	methods: {

	}
		
}